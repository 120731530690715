import './purpose.css'
import SubHeading from '../../../subHeading/SubHeading'

import imgUrl from '../../../../assets/img/general/asociacion_img.png'
import objectives from '../../../../assets/files/objectives.pdf';


export default function PurposeAlt() {
  return (
    <section className='purpose'>
      <div className='purpose__wrapper-img'>
        <img src={imgUrl} alt='Woman painting a mural' />
      </div>
      <div className='purpose__wrapper-info'>
        <SubHeading title='La Asociación' />
        <div className='purpose__content'>
          <p className='p__main'>Desde la Asociación de Cultura Menstrual, La Vida en Rojo, damos a conocer el nuevo paradigma de la Cultura Menstrual. Para ello promovemos una comprensión positiva y libre de estigmas sobre la vivencia cíclica, menstrual, climatérica y menopáusica.</p>
          <p className='p__main'>Esta forma de atender la educación y la salud menstrual y climatérica democratizan los cuidados y ayudan a combatir la pobreza menstrual,además de favorecer la erradicación de las violencias machistas, la igualdad de género, la equidad en el trabajo y el reconocimiento de la diversidad de experiencias.</p>
          <p className='p__main'>Los ejes de intervención de la Asociación de Cultura Menstrual, La Vida en Rojo, son:</p>
          <ul>
            <li className='p__main'>
              <a href='#activism'>
                Activismo
                <span className='arrow'>&rarr;</span>
              </a>
            </li>
            <li className='p__main'>
              <a href='#research'>
                Investigación
                <span className='arrow'>&rarr;</span>
              </a>
            </li>
            <li className='p__main'>
              <a href='#press'>
                Divulgación
                <span className='arrow'>&rarr;</span>
              </a>
            </li>
            <li className='p__main'>
              <a href='#education'>
                Educación
                <span className='arrow'>&rarr;</span>
              </a>
            </li>
            <li className='p__main'>
              <a href='#red'>
                Tejer Red
                <span className='arrow'>&rarr;</span>
              </a>
            </li>
          </ul>
        </div>

        <a 
          className='main__button'
          href='https://drive.google.com/file/d/1s84J7aWFVKZ4U64pDkxmGuRInY8LNhak/view?pli=1'
          target="_blank"
          rel='noreferrer'  
          style={{ textAlign: 'center' }}
        >Campaña 2025 #DerechosMenstruales<br/>¡ACTÚA!
        </a>
      </div>
    </section>
  )
}