import React from 'react'
import './press.css';
import Article from '../../../article/Article';
import Fanzine from '../../../fanzine/Fanzine';

import articlesData from '../../../../mocks/articles.json'
import fanzinesData from '../../../../mocks/fanzines.json'
import derechosmenstruales from '../../../../assets/img/materials/derechosmenstruales.png'
import SubHeading from '../../../subHeading/SubHeading';

const Press = () => {

    return (
        <section className='press section__padding' id='press'>
            <div className='press__heading'>
            <SubHeading title='Divulgación' color={true} />
                <p className='normal-text'>Coordinación y participación en charlas, formaciones, jornadas o seminarios.</p>
                <p className='normal-text'>Creación de material divulgativo sobre la menstruación, el ciclo menstrual y el climaterio.</p>
            </div>

            {/* Artículos */}
            <div className='press__articles'>
                <h4>Artículos</h4>
                <div className='press__articles-container'>
                    {articlesData.Articles.map((article) => 
                        <Article 
                            key={article.id} 
                            imgUrl={article.imgUrl} 
                            title={article.title} 
                            url={article.url}
                        />
                    )}
                </div> 
            </div>

            {/* Fanzines */}
            <div className='press__fanzines'>
                <h4>Fanzines</h4>
                <div className='press__fanzines-container'>
                    {fanzinesData.Fanzines.map((fanzine) =>
                        <Fanzine key={fanzine.id} href={fanzine.url} imgUrl={fanzine.imgUrl}/>
                    )}
                </div>
            </div>

            {/* Material */}
            {/* <div className='press__materials'>
                <h4>Material</h4>
                <div className='press__materials-container'>
                    <div className='press__materials-container__material'>
                        <a 
                            className="press__materials-container__material-link"
                            href='https://drive.google.com/file/d/1r4xnPS-Brn47ABpZeQ_IanPrHD1KVCI9/view'
                            target='_blank' 
                            rel='noreferrer'
                        >
                            <img 
                            className="press__materials-container__material-image" 
                            src={derechosmenstruales}
                            alt='Cover from the material element' 
                            />
                        </a>
                    </div>
                </div>
            </div> */}
        </section>
    )
}

export default Press
